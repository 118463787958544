import LanguageDetector from 'i18next-browser-languagedetector'
import i18n, { Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LnaLang, SeaLang } from '@/typings/common'
import enUsTrans from './en-us.json'
import zhTwTrans from './zh-tw.json'
import krTrans from './kr-kr.json'
import jpTrans from './jp-jp.json'

import seaEnTrans from './sea/en.json'
import seaThTrans from './sea/th.json'
import seaPtTrans from './sea/pt.json'
import seaEsTrans from './sea/es.json'
import seaInTrans from './sea/in.json'
import seaZhCnTrans from './sea/zh-cn.json'
import seaZhTwTrans from './sea/zh-tw.json'

import lnaEnTrans from './lna/en.json'
import lnaEsTrans from './lna/es.json'
import lnaPtTrans from './lna/pt.json'

export const isSea = import.meta.env.MODE?.includes('sea')
export const isLna = import.meta.env.MODE?.includes('lna')

let resources: Resource = {
  en: {
    translation: enUsTrans,
  },
  'zh-TW': {
    translation: zhTwTrans,
  },
  kr: {
    translation: krTrans,
  },
  jp: {
    translation: jpTrans,
  },
}
if (isSea) {
  resources = {
    [SeaLang.En]: {
      translation: seaEnTrans,
    },
    [SeaLang.Th]: {
      translation: seaThTrans,
    },
    [SeaLang.In]: {
      translation: seaInTrans,
    },
    [SeaLang.ZhCn]: {
      translation: seaZhCnTrans,
    },
    [SeaLang.ZhTw]: {
      translation: seaZhTwTrans,
    },
    [LnaLang.Es]: {
      translation: seaEsTrans,
    },
    [LnaLang.Pt]: {
      translation: seaPtTrans,
    },
  }
}
if (isLna) {
  resources = {
    [LnaLang.En]: {
      translation: lnaEnTrans,
    },
    [LnaLang.Es]: {
      translation: lnaEsTrans,
    },
    [LnaLang.Pt]: {
      translation: lnaPtTrans,
    },
  }
}

if (isSea || isLna) {
  i18n
    .use(LanguageDetector) // 嗅探当前浏览器语言
    .use(initReactI18next) // init i18next
    .init({
      // 引入资源文件
      resources,
      // 选择默认语言，选择内容为上述配置中的key，即en/zh
      fallbackLng: 'en',
      lng: localStorage.getItem('i18nextLng') || 'en',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      react: {
        useSuspense: false,
      },
    })
} else {
  i18n
    .use(LanguageDetector) // 嗅探当前浏览器语言
    .use(initReactI18next) // init i18next
    .init({
      // 引入资源文件
      resources,
      // 选择默认语言，选择内容为上述配置中的key，即en/zh
      fallbackLng: 'zh-TW',
      lng: localStorage.getItem('i18nextLng') || 'zh-TW',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    })
}

export default i18n
