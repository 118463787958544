import React, { Suspense } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Inspector } from 'react-dev-inspector'
import ReactDOM from 'react-dom'
import { renderRoutes } from 'react-router-config'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
// import 'core-js/stable'
import Kefu from '@/kefu'
import * as Sentry from '@sentry/react'
import { isPC } from '@/utils'
import reportWebVitals from './reportWebVitals'
import seaRoutes from './routes/sea'
import '@/lang/i18n'
import './index.css'

window.global ||= window
const isDev = !import.meta.env.MODE?.includes('production')

Sentry.init({
  dsn: 'https://a88681e653e8472794a85ef21b4f62be@sentry.123u.com/50',
  // release:'',
  integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
  tracesSampleRate: 1.0,
})

if (!isPC() && isDev) {
  import('vconsole').then((module) => {
    const VConsole = module.default
    // eslint-disable-next-line no-new
    new VConsole()
  })
}

const queryClient = new QueryClient()

const InspectorWrapper = isDev ? Inspector : React.Fragment

Kefu.init()

const App = () => {
  return (
    <InspectorWrapper keys={['control', 'shift', 'alt', 'x']}>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<></>}>
          <BrowserRouter>{renderRoutes(seaRoutes)}</BrowserRouter>
        </Suspense>
      </QueryClientProvider>
    </InspectorWrapper>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
