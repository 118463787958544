export enum Region {
  HMT = 'hmt',
  SEA = 'sea',
  LNA = 'lna',
}

export enum SeaLang {
  En = 'en', // 英语
  Th = 'th', // 泰语
  In = 'in', // 印尼语
  ZhCn = 'zh-CN', /* 简体中文 */
  ZhTw = 'zh-TW', /* 繁体中文 */
}

export enum LnaLang {
  En = 'en',
  Es = 'es', // 西班牙语
  Pt = 'pt', // 葡萄牙语
}
