export const isMobileLandscape = (): boolean => {
  // 新版浏览器可使用 window.screen.orientation
  if (window.screen.orientation) {
    const { type } = window.screen.orientation
    if (type.includes('landscape')) {
      return true
    }
  } else {
    // 兼容老版本
    if (window.orientation === 180 || window.orientation === 0) {
      return false
    }
    // 横屏状态
    if (window.orientation === 90 || window.orientation === -90) {
      return true
    }
  }
  return false
}

// 如果是移动端横屏状态，isPC会返回true。增加isPure参数，用来判断纯正的PC端
export const isPC = (isPure: boolean = false): boolean => {
  const userAgentInfo = navigator.userAgent
  const Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod',
  ]
  let flag = true
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = isPure ? false : isMobileLandscape()
      break
    }
  }
  return flag
}

export const getIosVersion = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/cpu iphone os (.*?) like mac os/)
}

/**
 * 判断是否是 ios 16.0 版本
 * ios 16.0 在 facebook 自带浏览器访问页面会有 bug
 */
export const getIsIos16 = () => {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/cpu iphone os (.*?) like mac os/)?.[1] === '16_0'
}

export const isAndroid = (): boolean => {
  return Boolean(navigator.userAgent.match(/android/gi))
}

export const isIos = (): boolean => {
  return Boolean(navigator.userAgent.match(/iPhone|iPad/gi))
}

const loadOneImg = (url: string) => {
  const img = new Image()
  img.src = url
}

export const loadImg = (url: string | string[]) => {
  if (Array.isArray(url)) {
    url.forEach((item) => loadOneImg(item))
    return
  }
  loadOneImg(url)
}

/**
 * WHY：
 * iso 用 - 拼接会输出 undefined： `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
 */
export const getCurrData = () => {
  const date = new Date()
  const currDate = `${date.getFullYear()}/${
    date.getMonth() + 1
  }/${date.getDate()}`
  return currDate
}

export const getDateFromTimestamp = (
  timestamp: number,
  format = 'YYYY-MM-DD'
) => {
  if (!timestamp) return ''
  const now = new Date(timestamp)
  const year = `${now.getFullYear()}`
  const month = `0${now.getMonth() + 1}`.slice(-2)
  const day = `0${now.getDate()}`.slice(-2)
  const defaultResult = `${year}-${month}-${day}`
  if (!format) return defaultResult
  return format.replace('YYYY', year).replace('MM', month).replace('DD', day)
}

export const loadScript = (url: string, callback?: () => void) => {
  const head =
    document.head ||
    document.getElementsByTagName('head')[0] ||
    document.documentElement
  const script = document.createElement('script') as any
  script.type = 'text/javascript'
  script.charset = 'utf-8'
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (/loaded|complete/i.test(script.readyState)) {
        script.onreadystatechange = null
        callback?.call(this)
      }
    }
  } else {
    script.onload = () => {
      callback?.call(this)
    }
  }
  script.src = url
  head.insertBefore(script, head.firstChild)
}

export const downloadIamge = (imgSrc: string, name = '') => {
  const image = new Image()
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous')
  image.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const context = canvas.getContext('2d')
    context?.drawImage(image, 0, 0, image.width, image.height)
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob!)
      const a = document.createElement('a')
      a.download = name
      a.href = url
      a.click()
      a.remove()
      URL.revokeObjectURL(url)
    })
  }
  image.src = imgSrc
}

export const scrollDomInToView = (anchor: string) => {
  if (!anchor) return
  const dom = document.getElementById(anchor)
  if (dom) {
    dom?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

export const getHrefParam = (): JsonObject => {
  const location = decodeURIComponent(window.location.href)
  if (location.indexOf('?') < 0) {
    return {}
  }
  const paramStr = location.split('?')[1]
  const res: JsonObject = {}
  if (paramStr) {
    paramStr.split('&').forEach((item) => {
      const [key, value] = item.split('=')
      res[key] = value
    })
  }
  return res
}

export const replaceName = (name = '') => {
  const { length } = name
  if (length > 10) {
    return `${name.substr(0, 3)}***${name.substr(length - 3)}`
  }
  return name
}

export const transferSecondsToTime = (seconds: number | string) => {
  if (typeof seconds === 'string') {
    seconds = Number(seconds.split('″')[0])
  }
  if (typeof seconds !== 'number') return ''
  const hours = Math.floor(seconds / (60 * 60))
  const minites = Math.floor((seconds - 60 * 60 * hours) / 60)
  const second = seconds - 60 * 60 * hours - 60 * minites
  let res = `${second}"`
  if (minites > 0) {
    res = `${minites}'${res}`
  }
  if (hours > 0) {
    res = `${hours}:${res}`
  }
  return res
}

export const updateRootLang = (lang: string) => {
  const root = document.querySelector('#root')
  const modalRoot = document.querySelector('#modal-root')
  modalRoot?.setAttribute('data-lang', lang)
  root?.setAttribute('data-lang', lang)
}

export const removeQueryParam = (url: string, key: string) => {
  // 使用正则表达式匹配查询参数
  const pattern = new RegExp(`[?&]${encodeURIComponent(key)}=([^&]*)`)
  const match = url.match(pattern)

  // 如果没有匹配到，直接返回原URL
  if (!match) {
    return url
  }

  // 删除匹配到的查询参数及其值
  const newUrl = url.replace(pattern, '')

  // 如果新URL还有查询参数，确保前缀是?而不是&
  return newUrl.startsWith('&') ? newUrl.substring(1) : newUrl
}
