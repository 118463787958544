import { getHrefParam } from '@/utils'
import { Region } from '@/typings/common'
import QY from './qy'
import AiHelp from './aihelp'

export enum KefuTypeEnum {
  aihelp = 'aihelp',
  qiyu = 'qiyu',
}

// 台港澳1/9,拉美1/11,东南亚1/17切换默认语言为qiyu
const regionDefaultKfType = {
  [Region.HMT]: KefuTypeEnum.qiyu,
  [Region.SEA]: KefuTypeEnum.qiyu,
  [Region.LNA]: KefuTypeEnum.qiyu,
}

export default class Kefu {
  public static init = () => {
    const urlParams = getHrefParam()
    const region: Region =
      (import.meta.env.MODE?.split('-')[1] as Region) || Region.HMT
    if (urlParams.kftype) {
      switch (urlParams.kftype) {
        case 'aihelp':
          window.currentKfType = KefuTypeEnum.aihelp
          break
        case 'qy':
          window.currentKfType = KefuTypeEnum.qiyu
          break
        default:
          window.currentKfType = regionDefaultKfType[region]
          break
      }
    } else {
      window.currentKfType = regionDefaultKfType[region]
    }
    switch (window.currentKfType) {
      case KefuTypeEnum.aihelp:
        AiHelp.init()
        break
      case KefuTypeEnum.qiyu:
        QY.init()
        break
      default:
        AiHelp.init()
        break
    }
  }

  public static open = () => {
    switch (window.currentKfType) {
      case KefuTypeEnum.aihelp:
        AiHelp.open()
        break
      case KefuTypeEnum.qiyu:
        QY.open()
        break
      default:
        AiHelp.open()
        break
    }
  }

  public static close = () => {
    switch (window.currentKfType) {
      case KefuTypeEnum.aihelp:
        AiHelp.close()
        break
      case KefuTypeEnum.qiyu:
        QY.close()
        break
      default:
        AiHelp.close()
        break
    }
  }
}
